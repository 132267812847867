<!--  -->
<template>
  <div class="form-wrapper">
    <van-form ref="form">
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypeOptions"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
      />
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="社会保障卡开户银行"
        placeholder="请输入社会保障卡开户银行"
        required
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="社会保障卡金融账号"
        placeholder="请输入社会保障卡金融账号"
        required
      />
      <van-field
        v-model="form.workCmpy"
        name="workCmpy"
        label="工作单位"
        placeholder="请输入工作单位"
        required
      />

      <van-field-select
        v-model="form.skillLevel"
        :options="skillLevelOptions"
        :fieldProps="{
          label: '技能级别',
          placeholder: '请选择技能级别',
          required: true,
        }"
      ></van-field-select>
      <van-field
        v-model="form.skillCertName"
        name="skillCertName"
        label="职业证书名称"
        placeholder="请输入职业证书名称"
        required
      />
      <van-field
        v-model="form.skillCertCode"
        name="skillCertCode"
        label="职业证书编号"
        placeholder="请输入职业证书编号"
        required
      />
    </van-form>
  </div>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import rules from "./rules";
export default {
  data() {
    return {
      form: {
        applyName: "",
        idCardType: "居民身份证",
        phone: "",
        idCardNum: "",
        bankAccount: "",
        bankId: "",
        workCmpy: "",
        skillLevel: "",
        skillCertCode: "",
        skillCertName: "",
      },
      rules: rules,
      skillLevelOptions: [
        {
          label: "首席技师",
          value: "首席技师",
        },
        {
          label: "特级技师",
          value: "特级技师",
        },
        {
          label: "高级技师",
          value: "高级技师",
        },
        {
          label: "技师",
          value: "技师",
        },
      ],
    };
  },
  mixins: [formMixin],
  props: {
    detail: Object,
  },
  mounted() {},
  methods: {
    validate() {
      const validator = new Schema(this.rules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(() => {
            resolve(this.form);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
}
.tip {
  margin: 0.2rem;
}
</style>
