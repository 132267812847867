export default {
  phone: [
    {
      message: "请输入手机号",
      required: true,
    },
    {
      message: "手机号格式不正确",
      pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
    },
  ],
  bankAccount: [
    {
      message: "请输入社会保障卡金融账号",
      required: true,
    },
    {
      message: "社会保障卡金融账号最多20字",
      max: 20,
    },
  ],
  bankId: [
    {
      message: "请输入社会保障卡开户银行",
      required: true,
    },
    {
      message: "社会保障卡开户银行最多100字",
      max: 100,
    },
  ],

  workCmpy: [
    {
      message: "请输入工作单位",
      required: true,
    },
    {
      message: "工作单位最多100字",
      max: 100,
    },
  ],
  skillLevel: [
    {
      message: "请输入技能级别",
      required: true,
    },
    {
      message: "技能级别最多100字",
      max: 100,
    },
  ],
  skillCertCode: [
    {
      message: "请输入职业证书编号",
      required: true,
    },
    {
      message: "职业证书编号最多100字",
      max: 100,
    },
  ],
  skillCertName: [
    {
      message: "请输入职业证书名称",
      required: true,
    },
    {
      message: "职业证书名称最多100字",
      max: 100,
    },
  ],
}